<template>
  <div class="box">
    <div class="flex">
      <el-select v-model="value" placeholder="请选择学校" size="small">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button class="ml-2" @click="handleSelectFile" size="small" type="primary" icon="el-icon-upload2" :disabled="value ? false:true">上传</el-button>
    </div>
  </div>
</template>

<script>
import { handleChooseFile } from "@/utils";
import { get_2024schools } from "@/api";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data() {
    return {
      value: '',
      options: []
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      get_2024schools().then(res => {
        const { entity_school = [] } = res
        this.options = entity_school.map(item => ({ label: item.school_name, value: item.id }))
      })
    },
    handleSelectFile() {
      handleChooseFile({
        data: { school_id: this.value },
        api: '/zhaosheng/api/recruit-2024/offline/gen-student-order-list',
        show: true,
        onSuccess: () => {this.$message.success('操作成功')}
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  padding-top: 30rem;
  padding-left: 30rem;
}
</style>
